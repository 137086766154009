import { Component, OnInit, Inject } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent implements OnInit {
  public action: string;
  public form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<LocationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: LocationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.action = this.data.action != null ? this.data.action : 'Create New';
    this.buildFormGroup();
  }
  private buildFormGroup() {
    this.form = this.formBuilder.group({
      locationId: [
        this.data.content != null ? this.data.content.locationId : 0
      ],
      code: [
        this.data.content != null ? this.data.content.code : '',
        Validators.required
      ],
      description: [
        this.data.content != null ? this.data.content.description : ''
      ],
      pipeline: [
        this.data.content != null ? this.data.content.pipeline : null,
        Validators.compose([
          Validators.min(0),
          Validators.max(100),
          Validators.required
        ])
      ],
      tankage: [
        this.data.content != null ? this.data.content.tankage : null,
        Validators.compose([
          Validators.min(0.0),
          Validators.max(100.0),
          Validators.required
        ])
      ]
    });
  }
  public save() {
    if (this.form.invalid) {
      return;
    }
    if (this.data.content === null || this.data.content.locationId === 0) {
      this.service.insert(this.form.value).subscribe(() => {
        this.dialogRef.close('success');
      });
    } else {
      this.service.update(this.form.value).subscribe(() => {
        this.dialogRef.close('success');
      });
    }
  }
  public getControl(name: string) {
    return this.form.get(name);
  }
  public cancel() {
    this.dialogRef.close('cancelled');
  }
}
