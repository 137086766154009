export class OwnersFileHistory {
  ownersFileHistoryId: number;
  createdDate: Date;
  createdBy: string;
  year: number;
  month: number;
  statusDescription: string;
  statusNumber: number;
  postedSapDate: Date;
  ownersDataRowsCount: number;
}
