import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { AzureAdSettings } from './azure-ad-settings';

import {
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatMenuModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatDialogModule,
  MatStepperModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OwnershipGetComponent } from './components/ownerships/ownership-get/ownership-get.component';
import { OwnershipEditComponent } from './components/ownerships/ownership-edit/ownership-edit.component';
import { AlyeskaAccountGetComponent } from './components/alyeska-accounts/alyeska-account-get/alyeska-account-get.component';
import { AlyeskaAccountEditComponent } from './components/alyeska-accounts/alyeska-account-edit/alyeska-account-edit.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ErrorComponent } from './components/common/error/error.component';
import { SpinnerService } from './services/spinner.service';
import { HttpSpinnerInterceptor } from './interceptors/http-spinner-interceptor';
import { SnackbarComponent } from './components/common/snackbar/snackbar.component';
import { LocationGetComponent } from './components/locations/location-get/location-get.component';
import { LocationEditComponent } from './components/locations/location-edit/location-edit.component';
import { OwnersFileGetComponent } from './components/owners-files/owners-file-get/owners-file-get.component';
import { OwnersFileStepsComponent } from './components/owners-files/owners-file-steps/owners-file-steps.component';
import { Step1UploadFileComponent } from './components/owners-files/owners-file-steps/step1-upload-file/step1-upload-file.component';
import { Step1TrialBalanceComponent } from './components/owners-files/owners-file-steps/step1-trial-balance/step1-trial-balance.component';
// tslint:disable-next-line: max-line-length
import { Step2DataValidationComponent } from './components/owners-files/owners-file-steps/step2-data-validation/step2-data-validation.component';
// tslint:disable-next-line: max-line-length
import { Step3ResultPreviewComponent } from './components/owners-files/owners-file-steps/step3-result-preview/step3-result-preview.component';
import { Step4PostSapComponent } from './components/owners-files/owners-file-steps/step4-post-sap/step4-post-sap.component';
import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm-dialog.component';
import { AccountMappingEditComponent } from './components/account-mappings/alyeska-account-edit/account-mapping-edit.component';
import { AccountMappingGetComponent } from './components/account-mappings/account-mapping-get/account-mapping-get.component';
import { NgxMaskModule } from 'ngx-mask';
import { IConfig } from 'ngx-mask/lib/config';
import { AuditComponent } from './components/audit/audit.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    [
      'https://alyeska-api-acc.azurewebsites.net/api',
      ['api://90fb0d92-f7b2-4f1f-a3e9-5793e2cf084e/general']
    ]  
];

const isIE = /MSIE|Trident/.test(window.navigator.userAgent);

@NgModule({
  declarations: [
    AppComponent,
    OwnershipGetComponent,
    OwnershipEditComponent,
    AlyeskaAccountGetComponent,
    AlyeskaAccountEditComponent,
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    SnackbarComponent,
    LocationGetComponent,
    LocationEditComponent,
    OwnersFileGetComponent,
    OwnershipEditComponent,
    OwnersFileStepsComponent,
    Step1UploadFileComponent,
    Step1TrialBalanceComponent,
    Step2DataValidationComponent,
    Step3ResultPreviewComponent,
    Step4PostSapComponent,
    ConfirmDialogComponent,
    AccountMappingEditComponent,
    AccountMappingGetComponent,
    AuditComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(options),
    MsalModule.forRoot(
      {
        auth: {
          clientId: AzureAdSettings.clientId,
          authority:
            'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906',
          validateAuthority: true,
          redirectUri: 'https://acc.alyeska.xom.cloud',
          postLogoutRedirectUri: 'https://acc.alyeska.xom.cloud',
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
        system: {
          loadFrameTimeout: 30000,
        }
      },
      {
        popUp: !isIE,
        consentScopes: ['user.read.all', 'openid', 'profile'],
        unprotectedResources: ['https://www.microsoft.com/en-us/'],
        protectedResourceMap,
      },
    ),
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
  ],
  entryComponents: [
    SnackbarComponent,
    OwnershipEditComponent,
    AlyeskaAccountEditComponent,
    ConfirmDialogComponent,
    AccountMappingEditComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSpinnerInterceptor,
      multi: true,
    },
    SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, verticalPosition: 'top' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
