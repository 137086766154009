import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Ownership } from '../models/ownership';
import { environment } from 'src/environments/environment';
import { OwnershipType } from '../models/ownership-type';
import { PagedList } from '../models/paged-list';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OwnershipService {
  constructor(private httpClient: HttpClient) {}

  public getAll(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<Ownership[]>(`${environment.apiEndpoint}/v1/ownerships/`, {
        params: filter,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }
  public getById(id: number): Observable<Ownership> {
    return this.httpClient.get<Ownership>(
      `${environment.apiEndpoint}/v1/ownerships/${id}`,
    );
  }
  public insert(model: Ownership): Observable<Ownership> {
    return this.httpClient.post<Ownership>(
      `${environment.apiEndpoint}/v1/ownerships/`,
      model,
    );
  }
  public update(model: Ownership): Observable<Ownership> {
    return this.httpClient.put<Ownership>(
      `${environment.apiEndpoint}/v1/ownerships/`,
      model,
    );
  }
  public delete(id: number): Observable<Ownership> {
    return this.httpClient.delete<Ownership>(
      `${environment.apiEndpoint}/v1/ownerships/${id}`,
    );
  }

  public getOwnershipTypes(): Observable<OwnershipType[]> {
    return this.httpClient.get<OwnershipType[]>(
      `${environment.apiEndpoint}/v1/ownerships/types`,
    );
  }
}
