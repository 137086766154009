import { Component, OnInit, Inject } from '@angular/core';
import { OwnershipType } from 'src/app/models/ownership-type';
import { OwnershipService } from 'src/app/services/ownership.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-ownership-edit',
  templateUrl: './ownership-edit.component.html',
  styleUrls: ['./ownership-edit.component.scss']
})
export class OwnershipEditComponent implements OnInit {
  public action: string;
  public ownershipTypes: OwnershipType[] = [];
  public form: FormGroup;
  public months: number[] = [];
  public years: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<OwnershipEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: OwnershipService,
    private formBuilder: FormBuilder
  ) {
    this.loadMonths();
    this.loadYears();
  }

  ngOnInit() {
    this.action = this.data.action != null ? this.data.action : 'Create New';
    setTimeout(() => this.loadOwnershipTypes());
  }

  private loadMonths() {
    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }
  }
  private loadYears() {
    const currentYear = (moment().format('YYYY') as unknown) as number;
    const limitYear = (moment().add(3, 'years').format('YYYY') as unknown) as number;
    for (let i = currentYear - 5; i <= limitYear; i++) {
      this.years.push(i);
    }
  }
  private loadOwnershipTypes() {
    this.service.getOwnershipTypes().subscribe((resp: OwnershipType[]) => {
      this.ownershipTypes = resp;
      this.buildFormGroup();
    });
  }
  private buildFormGroup() {
    this.form = this.formBuilder.group({
      ownershipId: [
        this.data.content != null ? this.data.content.ownershipId : 0
      ],
      startPeriodMonth: [
        this.data.content != null ? this.data.content.startPeriodMonth : '',
        Validators.required
      ],
      startPeriodYear: [
        this.data.content != null ? this.data.content.startPeriodYear : '',
        Validators.required
      ],
      endPeriodMonth: [
        this.data.content != null ? this.data.content.endPeriodMonth : '',
        Validators.required
      ],
      endPeriodYear: [
        this.data.content != null ? this.data.content.endPeriodYear : '',
        Validators.required
      ],
      percentage: [
        this.data.content != null ? this.data.content.percentage : null,
        Validators.compose([
          Validators.min(0.0),
          Validators.max(100.0),
          Validators.required
        ])
      ],
      ownershipTypeId: [
        this.data.content != null
          ? this.data.content.ownershipType.ownershipTypeId
          : '',
        Validators.required
      ]
    });
  }

  public save() {
    if (this.form.invalid) {
      return;
    }
    if (this.data.content === null) {
      this.service.insert(this.form.value).subscribe(() => {
        this.dialogRef.close('success');
      });
    } else {
      this.service.update(this.form.value).subscribe(() => {
        this.dialogRef.close('success');
      });
    }
  }
  public getControl(name: string) {
    return this.form.get(name);
  }
  public cancel() {
    this.dialogRef.close('cancelled');
  }
}
