import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class HttpSpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.spinnerService.show();
    return next.handle(request).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          this.spinnerService.hide();
        }
      }),
      catchError(err => {
        this.spinnerService.hide();
        throw err;
      })
    );
  }
}
