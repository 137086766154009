import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PagedList } from '../models/paged-list';
import { environment } from 'src/environments/environment';
import { OwnersFileHistory } from '../models/owners-file-history';
import { OwnersFileTrialBalance } from '../models/owners-file-trial-balance';
import { OwnersFileDataIssue } from '../models/owners-file-data-issue';
import { OwnersDataResultPreview } from '../models/owners-data-result-preview';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OwnersFileHistoryService {
  constructor(private httpClient: HttpClient) {}

  public getAll(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<OwnersFileHistory[]>(`${environment.apiEndpoint}/v1/ownersfiles/`, {
        params: filter,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }

  public getById(id: number): Observable<OwnersFileHistory> {
    return this.httpClient.get<OwnersFileHistory>(
      `${environment.apiEndpoint}/v1/ownersfiles/${id}`,
    );
  }

  public getYears(): Observable<number[]> {
    return this.httpClient.get<number[]>(
      `${environment.apiEndpoint}/v1/ownersfiles/years`,
    );
  }

  public getMonths(): Observable<number[]> {
    return this.httpClient.get<number[]>(
      `${environment.apiEndpoint}/v1/ownersfiles/months`,
    );
  }

  public getOwnersDataIssues(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<OwnersFileDataIssue[]>(
        `${environment.apiEndpoint}/v1/ownersfiles/dataissues`,
        {
          params: filter,
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }

  public getTrialBalance(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<OwnersFileTrialBalance[]>(
        `${environment.apiEndpoint}/v1/ownersfiles/trialbalances`,
        {
          params: filter,
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }

  public getTrialBalanceFile(
    ownersFileHistoryId: number,
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(
      `${environment.apiEndpoint}/v1/ownersfiles/${ownersFileHistoryId}/trialbalances/downloads`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  public getOriginalFile(
    ownersFileHistoryId: number,
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(
      `${environment.apiEndpoint}/v1/ownersfiles/${ownersFileHistoryId}/originalfile/downloads`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }
  public process(id: number): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.apiEndpoint}/v1/ownersfiles/${id}/process`,
      null,
    );
  }

  public postOwnersFile(
    model: any,
    overwrite = false,
    version: number = 1,
  ): Observable<OwnersFileHistory> {
    const customHeaders = new HttpHeaders();
    customHeaders.set('Content-Type', 'multipart/form-data');
    return this.httpClient.post<OwnersFileHistory>(
      `${environment.apiEndpoint}/v${version}/ownersfiles/imports${
        overwrite ? '/overwrite' : ''
      }`,
      model,
      {
        headers: customHeaders,
      },
    );
  }

  public getResulPreview(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<OwnersDataResultPreview[]>(
        `${environment.apiEndpoint}/v1/ownersfiles/process-result`,
        {
          params: filter,
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }
  public getResulPreviewFile(
    ownersFileHistoryId: number,
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(
      `${environment.apiEndpoint}/v1/ownersfiles/${ownersFileHistoryId}/process-result/downloads`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  public delete(id: number): Observable<OwnersFileHistory> {
    return this.httpClient.delete<OwnersFileHistory>(
      `${environment.apiEndpoint}/v1/ownersfiles/${id}`,
    );
  }

  public moveSapFileToDataFactoryFolder(
    ownersFileHistoryId: number,
  ): Observable<any> {
    return this.httpClient.put(
      `${environment.apiEndpoint}/v1/ownersfiles/${ownersFileHistoryId}/sapfiles`,
      {},
    );
  }

  public generateSapFile(ownersFileHistoryId: number): Observable<any> {
    return this.httpClient.post(
      `${environment.apiEndpoint}/v1/ownersfiles/${ownersFileHistoryId}/sapfiles`,
      {},
    );
  }

  public getSapFile(
    ownersFileHistoryId: number,
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(
      `${environment.apiEndpoint}/v1/ownersfiles/${ownersFileHistoryId}/sapfiles/downloads`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }
}
