import { Component, OnInit } from '@angular/core';
import { AuditService } from 'src/app/services/audit.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import * as FileSaver from "file-saver";

const DateRangeCustomValidator: ValidatorFn = (fg: FormGroup) => {
  const periodValue = fg.get('selectedAuditPeriod').value;

  if (periodValue !== -1) {
    return null;
  }

  const endDateValue = fg.get('endDatePickerValue').value;
  const startDateValue = fg.get('startDatePickerValue').value;

  if ((periodValue === -1 && endDateValue === '') && (periodValue === -1 && startDateValue === '')) {
    return { dateRange: true};
  } else if (startDateValue !== '' && endDateValue !== '') {

    const startDateMoment = moment(startDateValue);
    const endDateMoment = moment(endDateValue);

    if (endDateMoment.isAfter(startDateMoment)) {
      return null;
    }  else {
      return { dateRange: true};
    }
  }
  return { dateRange: true};
};

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {

  public auditTypeList = [];
  public auditPeriods = [];
  public form: FormGroup;
  public formIsReady = false;

  constructor(private auditService: AuditService,
              private snackbarService: SnackbarService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.auditPeriods = [
      { label: '1 day',  key: 1},
      { label: 'Latest 30 days', key: 30},
      { label: 'Latest 60 days', key: 60 },
      { label: 'Latest 90 days', key: 90 },
      { label: 'Everything (since beginning)', key: 0},
      { label: 'Start/End Date', key: -1}
    ];

    this.auditTypeList = [
      { label: 'All', key: 'all'},
      { label: 'Alyeska Accounts', key: 'alyeskaaccounts'},
      { label: 'Account Mappings', key: 'accountmappings'},
      { label: 'Locations', key: 'locations'},
      { label: 'Ownerships', key: 'ownerships'}
    ];

    this.form = this.formBuilder.group({
      selectedAuditPeriod: [1, [Validators.required]],
      selectedAuditType: ['all', [Validators.required]],
      startDatePickerValue: [''],
      endDatePickerValue: [''],
    }, { validators: DateRangeCustomValidator } );

    this.disableDatePickers();

    this.formIsReady = true;
  }

  private getFormValue(controlName: string) {
    return this.form.get(controlName).value;
  }

  private enableDatePickers() {
    this.form.get('startDatePickerValue').enable( { onlySelf: true});
    this.form.get('endDatePickerValue').enable( { onlySelf: true});
  }

  private disableDatePickers() {
    this.form.get('startDatePickerValue').disable( { onlySelf: true});
    this.form.get('endDatePickerValue').disable( { onlySelf: true});
  }

  public onPeriodRadioChange() {
    if (this.getFormValue('selectedAuditPeriod') === -1) {
      this.enableDatePickers();
    } else {
      this.disableDatePickers();
      this.getControl('startDatePickerValue').setValue('');
      this.getControl('endDatePickerValue').setValue('');
    }
  }

  public getControl(name: string) {
    return this.form.get(name);
  }

  public downloadAuditLog() {

    if (this.form.invalid) {
      if (this.form.errors != null && this.form.errors.dateRange) {
        // tslint:disable-next-line: max-line-length
        this.snackbarService.displayErrorMessage('Invalid dates provided; Dates can not be empty and \'From date\' must be before \'To date\'.', true);
      }
      return;
    }

    this.auditService.getAuditLog(
      this.getFormValue('selectedAuditType'),
      this.getFormValue('selectedAuditPeriod'),
      this.getFormValue('startDatePickerValue'),
      this.getFormValue('endDatePickerValue')
    ).subscribe((resp: any) => {
      if (resp.body.size > 0) {
        FileSaver.saveAs(resp.body, 'audit-log.xlsx');
      }
    }, (httpErrorResponse: HttpErrorResponse) => {
      if (httpErrorResponse.status === 406) {
        this.snackbarService.displayInfoMessage('Audit Log file not available for given period.');
      }

      if (httpErrorResponse.status === 400) {
        this.snackbarService.displayErrorMessage('Invalid input data provided; please review and try again.', true);
      }
    });
  }

}
