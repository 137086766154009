import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { OwnershipService } from 'src/app/services/ownership.service';
import { Ownership } from 'src/app/models/ownership';
import { MatPaginator, MatSort } from '@angular/material';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { DialogService } from 'src/app/services/dialog.service';
import { OwnershipEditComponent } from '../ownership-edit/ownership-edit.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';
import { PagedList } from 'src/app/models/paged-list';
import {
  ConfirmDialogComponent,
  ConfirmDialogContent
} from '../../common/confirm-dialog/confirm-dialog.component';
import { OwnershipType } from 'src/app/models/ownership-type';

@Component({
  selector: 'app-ownership-get',
  templateUrl: './ownership-get.component.html',
  styleUrls: ['./ownership-get.component.scss']
})
export class OwnershipGetComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'startPeriodYear',
    'endPeriodYear',
    'percentage',
    'ownershipType',
    'actions'
  ];
  public resultsLength = 0;
  public data: Ownership[] = [];
  public filterForm: FormGroup;
  public ownershipTypes: OwnershipType[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private service: OwnershipService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    this.filterForm = this.formBuilder.group({
      ownershipTypeId: ''
    });
  }

  ngAfterViewInit() {
    this.loadOwnershipTypes();
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadOwnerships(this.getFilter(false));
        }),
        map(data => {
          this.resultsLength = data.totalRecordCount;
          return data.items;
        })
      )
      .subscribe(data => (this.data = data));
  }

  private loadOwnershipTypes() {
    this.service.getOwnershipTypes().subscribe((resp: OwnershipType[]) => {
      this.ownershipTypes = resp;
    });
  }
  public loadOwnerships(filter: any = null): Observable<PagedList> {
    return this.service.getAll(filter);
  }
  public editItem(value: Ownership) {
    this.dialogService
      .openDialog(OwnershipEditComponent, null, {
        action: 'Edit',
        content: value
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Ownership edited successfully'
          );
        }
        this.filterList();
      });
  }
  public newItem() {
    this.dialogService
      .openDialog(OwnershipEditComponent, null, {
        action: 'Create New',
        content: null
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Ownership created successfully'
          );
          this.filterList();
        }
      });
  }

  public deleteItem(ownership: Ownership) {
    this.dialogService
      .openDialog(
        ConfirmDialogComponent,
        null,
        new ConfirmDialogContent(
          null,
          'Do you really want to delete this ownership?'
        )
      )
      .subscribe(resp => {
        if (resp) {
          this.service.delete(ownership.ownershipId).subscribe(() => {
            this.snackbarService.displaySuccessMessage(
              'Ownership deleted successfully'
            );
            this.filterList();
          });
        }
      });
  }

  public getFilter(resetPageIndex: boolean): any {
    const filter = this.filterForm.value;
    if (resetPageIndex) {
      this.paginator.pageIndex = 0;
      filter.pageNumber = 1;
    } else {
      filter.pageNumber = this.paginator.pageIndex + 1;
    }

    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;

    filter.pageSize = this.paginator.pageSize;
    return filter;
  }

  public filterList() {
    this.loadOwnerships(this.getFilter(true)).subscribe(data => {
      this.data = data.items;
      this.resultsLength = data.totalRecordCount;
    });
  }
}
