import {
  Component,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { OwnersFileDataIssue } from 'src/app/models/owners-file-data-issue';
import { MatPaginator } from '@angular/material';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AlyeskaAccountEditComponent } from 'src/app/components/alyeska-accounts/alyeska-account-edit/alyeska-account-edit.component';
import { AlyeskaAccountService } from 'src/app/services/alyeska-account.service';
import { AccountMappingService } from 'src/app/services/account-mapping.service';
import { AccountMappingEditComponent } from 'src/app/components/account-mappings/alyeska-account-edit/account-mapping-edit.component';
import { LocationEditComponent } from 'src/app/components/locations/location-edit/location-edit.component';
import { Location } from 'src/app/models/location';
import { startWith, switchMap, map } from 'rxjs/operators';
import { merge } from 'rxjs';

@Component({
  selector: 'app-step2-data-validation',
  templateUrl: './step2-data-validation.component.html',
  styleUrls: ['./step2-data-validation.component.scss']
})
export class Step2DataValidationComponent implements AfterViewInit {
   public validationDisplayedColumns: string[] = [
    'errorIndicator',
    'message',
    'action'
  ];

  public ownersFileDataIssues: OwnersFileDataIssue[] = [];

  public resultsLength = -1;

  public ownersFileHistory: OwnersFileHistory;

  @Input('ownersFileHistory')
  set ownersFileHistoryInput(value: OwnersFileHistory) {
    if (value) {
      this.ownersFileHistory = value;
    }
  }

  @Output() ownersFileHistoryOut = new EventEmitter<OwnersFileHistory>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() stepIndex: number;

  constructor(
    private ownsersFileDataService: OwnersFileHistoryService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private alyeskaAccountService: AlyeskaAccountService,
    private accountMappingService: AccountMappingService
  ) {}

  ngAfterViewInit() {
    this.init();
  }

  private init() {
    if (this.stepIndex === 2) {
      this.paginator.pageIndex = 0;
      merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadIssues(
            this.getFilter(this.ownersFileHistory.ownersFileHistoryId)
          );
        }),
        map(data => {
          this.resultsLength = data.totalRecordCount;
          if (
            this.resultsLength == 0 &&
            this.ownersFileHistory.statusNumber <= 2
          ) {
            this.ownersFileHistory.statusNumber = 3;
            this.ownersFileHistoryOut.emit(this.ownersFileHistory);
          }
          return data.items;
        })
      )
      .subscribe(data => {
        this.ownersFileDataIssues = data;
      });
    }
  }

  loadIssues(filter: any) {
    return this.ownsersFileDataService.getOwnersDataIssues(filter);
  }

  getErrorMessage(errorContent: any): string {
    if (errorContent.type === 1) {
      return `${errorContent.message} for ferc ${errorContent.ferc} and account ${errorContent.account}`;
    } else if (errorContent.type === 2) {
      return `${errorContent.message} for ferc ${errorContent.ferc} and account ${errorContent.account}`;
    } else  {
      return `${errorContent.message} ${errorContent.location}`;
    }
  }

  public getFilter(ownersFileId: number): any {
    const filter: any = { ownersFileHistoryId: ownersFileId };
    filter.pageNumber = this.paginator.pageIndex + 1;
    filter.pageSize = this.paginator.pageSize;
    filter.ownersFileHistoryId = this.ownersFileHistory.ownersFileHistoryId;
    return filter;
  }
  public goToFixIssue(issue: OwnersFileDataIssue) {
    switch (issue.type) {
      case 1:
        this.fixAlyeskaAccount(issue);
        break;
      case 2:
        this.fixAccountMapping(issue);
        break;
      case 3:
        this.fixLocation(issue);
        break;
    }
  }

  fixAlyeskaAccount(issue: OwnersFileDataIssue) {
    this.alyeskaAccountService.getById(issue.entityId).subscribe(result => {
      this.dialogService
        .openDialog(AlyeskaAccountEditComponent, null, {
          action: 'Edit',
          content: result
        })
        .subscribe(resp => {
          if (resp !== 'cancelled') {
            this.snackbarService.displaySuccessMessage(
              'Alyeska Account edited successfully'
            );
            this.init();
          }
        });
    });
  }

  fixAccountMapping(issue: OwnersFileDataIssue) {
    this.accountMappingService.getById(issue.entityId).subscribe(result => {
      this.dialogService
        .openDialog(AccountMappingEditComponent, null, {
          action: 'Edit',
          content: result
        })
        .subscribe(resp => {
          if (resp !== 'cancelled') {
            this.snackbarService.displaySuccessMessage(
              'Account mapping edited successfully'
            );
            this.init();
          }
        });
    });
  }

  fixLocation(issue: OwnersFileDataIssue) {
    const newLocation = new Location();
    newLocation.code = issue.location;
    newLocation.locationId = 0;

    this.dialogService
      .openDialog(LocationEditComponent, null, {
        action: 'Edit',
        content: newLocation
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Location created successfully'
          );
          this.init();
        }
      });
  }
}