export class PagedList {
  constructor(response) {
    this.items = response.body;
    this.pageNumber = response.headers.get('X-Paging-PageNo');
    this.pageSize = response.headers.get('X-Paging-PageSize');
    this.pageCount = response.headers.get('X-Paging-PageCount');
    this.totalRecordCount = response.headers.get('X-Paging-TotalRecordCount');
  }
  pageNumber: number;
  pageSize: number;
  totalRecordCount: number;
  pageCount: number;
  items: any;
}
