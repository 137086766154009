import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit
} from '@angular/core';
import { OwnersFileTrialBalance } from 'src/app/models/owners-file-trial-balance';
import { MatPaginator, MatSort, MatStepper } from '@angular/material';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { PagedList } from 'src/app/models/paged-list';
import { map, startWith, switchMap } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-step1-trial-balance',
  templateUrl: './step1-trial-balance.component.html',
  styleUrls: ['./step1-trial-balance.component.scss']
})
export class Step1TrialBalanceComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'ferc',
    'account',
    'previousMonthEndingBalance',
    'currentMonthBeginningBalance'
  ];
  public resultsLength = 0;
  public trialBalanceData: OwnersFileTrialBalance[] = [];
  public showTrialBalance = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public ownersFileHistory: OwnersFileHistory;
  @Input('ownersFileHistory')
  set ownersFileHistoryInput(value: OwnersFileHistory) {
    if (value) {
      this.ownersFileHistory = value;
    }
  }
  @Input() stepIndex: number;

  constructor(private ownersFileDataService: OwnersFileHistoryService) {}

  ngAfterViewInit() {
    if (this.stepIndex === 1) {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.loadTrialBalance(
              this.getFilter(this.ownersFileHistory.ownersFileHistoryId)
            );
          }),
          map(data => {
            this.resultsLength = data.totalRecordCount;

            return data.items;
          })
        )
        .subscribe(data => {
          this.trialBalanceData = data;
          this.showTrialBalance = true;
        });
    }
  }

  public getFilter(ownersFileId: number): any {
    const filter: any = { ownersFileHistoryId: ownersFileId };
    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;
    filter.pageNumber = this.paginator.pageIndex + 1;
    filter.pageSize = this.paginator.pageSize;
    return filter;
  }

  public loadTrialBalance(filter: any = null): Observable<PagedList> {
    return this.ownersFileDataService.getTrialBalance(filter);
  }

  public downloadTrailBalanceFile() {
    this.ownersFileDataService
      .getTrialBalanceFile(this.ownersFileHistory.ownersFileHistoryId)
      .subscribe((resp: any) => {
        if (resp.body.size > 0) {
          saveAs(resp.body, 'trial-balance.xlsx');
        }
      });
  }
}
