import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export class ConfirmDialogContent{
  constructor(public modalTitle: string, public message: string) {}
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public modalTitle = 'Are you sure?';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data != null && data.modalTitle != null) {
      this.modalTitle = data.modalTitle;
    }
  }
  
  ngOnInit() {
  }
  public confirm() {
    this.dialogRef.close(true);
  }
  public cancel() {
    this.dialogRef.close(false);
  }
}
