import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar.service';
import { ResponseError } from '../models/response-error';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          //  client-side error
          errorMessage = `Error: ${error.error.message}`;
          this.logErrorMessage(errorMessage);
        } else {
          //  server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          this.logErrorMessage(errorMessage);
          if (error.status === 400 || error.status === 409) {
            const errorObj = JSON.parse(
              JSON.stringify(error.error)
            ) as ResponseError;
            this.snackbarService.displayErrorMessage(errorObj, true);
          } else if (error.status === 406) {
            return throwError(error);
          } else {
            this.router.navigate(['/error']);
          }
        }
        return throwError(error);
      })
    );
  }

  private logErrorMessage(errorMessage: string) {
    // For now, we will log it in the console
    console.log(errorMessage);
  }
}
