import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { ResponseError } from 'src/app/models/response-error';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  public message: string | any;
  public responseError: ResponseError;
  public iconName: any;

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string | any
  ) {
    if (typeof data.message === 'string') {
      this.message = data.message;
      this.setIconName(data.messageType);
    } else {
      this.responseError = data.message;
    }
  }

  public getErrorsKeyArray() {
    return Object.keys(this.responseError.errors);
  }

  private setIconName(type: string) {
    switch (type) {
      case 'error':
        this.iconName = 'error_outline';
        break;
      case 'success':
        this.iconName = 'check_circle_outline';
        break;
      case 'warn':
        this.iconName = 'warning';
        break;
      case 'info':
        this.iconName = 'info';
        break;
      default:
        this.iconName = null;
    }
  }
  public closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}
