import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  public openDialog(dialogComponent: any, dialogWidth?: number, data?: any): Observable<any> {
    const dialogRef = this.dialog.open(dialogComponent, {
      width: `${(dialogWidth != null ? dialogWidth : dialogWidth)}`,
      data,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }
}
