import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlyeskaAccountGetComponent } from './components/alyeska-accounts/alyeska-account-get/alyeska-account-get.component';
import { AlyeskaAccountEditComponent } from './components/alyeska-accounts/alyeska-account-edit/alyeska-account-edit.component';
import { OwnershipGetComponent } from './components/ownerships/ownership-get/ownership-get.component';
import { OwnershipEditComponent } from './components/ownerships/ownership-edit/ownership-edit.component';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorComponent } from './components/common/error/error.component';
import { LocationGetComponent } from './components/locations/location-get/location-get.component';
import { LocationEditComponent } from './components/locations/location-edit/location-edit.component';
import { OwnersFileStepsComponent } from './components/owners-files/owners-file-steps/owners-file-steps.component';
import { OwnersFileGetComponent } from './components/owners-files/owners-file-get/owners-file-get.component';
import { AccountMappingEditComponent } from './components/account-mappings/alyeska-account-edit/account-mapping-edit.component';
import { AccountMappingGetComponent } from './components/account-mappings/account-mapping-get/account-mapping-get.component';
import { AuditComponent } from './components/audit/audit.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/uploads',
    pathMatch: 'full',
  },
  {
    path: 'uploads',
    component: OwnersFileGetComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'uploads/create',
    component: OwnersFileStepsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'uploads/edit/:id',
    component: OwnersFileStepsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'alyeska-accounts',
    component: AlyeskaAccountGetComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'alyeska-accounts/create',
    component: AlyeskaAccountEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'alyeska-accounts/edit/:id',
    component: AlyeskaAccountEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ownerships',
    component: OwnershipGetComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ownerships/create',
    component: OwnershipEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ownerships/edit/:id',
    component: OwnershipEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'locations',
    component: LocationGetComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'locations/create',
    component: LocationEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'locations/edit/:id',
    component: LocationEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'account-mappings',
    component: AccountMappingGetComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'account-mappings/create',
    component: AccountMappingEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'account-mappings/edit/:id',
    component: AccountMappingEditComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'audit-logs',
    component: AuditComponent,
    canActivate: [MsalGuard],
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '/uploads' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
