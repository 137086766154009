import { Component, ViewChild, ElementRef, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective
} from '@angular/forms';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ConfirmDialogComponent,
  ConfirmDialogContent
} from 'src/app/components/common/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-step1-upload-file',
  templateUrl: './step1-upload-file.component.html',
  styleUrls: ['./step1-upload-file.component.scss']
})
export class Step1UploadFileComponent {
  public disableImportButton = true;
  public ownersFileVersionOptions = [
    { label: 'Version 1', value:  1 },
    { label: 'Version 2', value:  2 }
  ];

  form: FormGroup;
  @ViewChild('fileInput', null) fileInput: ElementRef;

  @Output() ownersFileHistoryOut = new EventEmitter<OwnersFileHistory>();

  constructor(
    private fb: FormBuilder,
    private ownersFileHistoryService: OwnersFileHistoryService,
    private dialogService: DialogService,
    private router: Router,
    private snacnarService: SnackbarService
  ) {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      ownersFileVersion: this.ownersFileVersionOptions[1].value,
      fileName: ['', Validators.required],
      file: null
    });
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get('fileName').setValue(file.name);
        this.form.get('file').setValue(file);
      };
    }
  }

  clearFile(formDirective: FormGroupDirective) {
    this.form.reset();
    formDirective.resetForm();
    this.fileInput.nativeElement.value = '';
  }

  onSubmit(formDirective: FormGroupDirective, overwrite?: boolean) {
    const formData = new FormData();
    formData.append(
      'uploadedFiles',
      this.form.value.file,
      this.form.value.filename
    );

    this.ownersFileHistoryService.postOwnersFile(formData, overwrite, this.form.value.ownersFileVersion).subscribe(
      (result: OwnersFileHistory) => {
        this.snacnarService.displaySuccessMessage(
          'Imported file validated successfully!'
        );
        this.ownersFileHistoryOut.emit(result);
        this.router.navigate([`/uploads/edit/${result.ownersFileHistoryId}`]);
      },
      (httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.status === 406) {
          this.dialogService
            .openDialog(
              ConfirmDialogComponent,
              null,
              new ConfirmDialogContent(
                httpErrorResponse.error.title,
                `${httpErrorResponse.error.errors['validation Error']} Are you sure about to continue and replace existent file?`
              )
            )
            .subscribe((resp: any) => {
              if (resp) {
                this.onSubmit(formDirective, true);
              }
            });
        }
      }
    );
  }
}
