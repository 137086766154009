import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public spinnerCount = 0;
  show() {
    this.spinnerCount += 1;
    this.status.next(true);
  }

  hide() {
    this.spinnerCount -= 1;
    if (this.spinnerCount <= 0) {
      this.spinnerCount = 0;
      this.status.next(false);
    }
  }
}
