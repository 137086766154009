import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import { MatStepper } from '@angular/material';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogComponent, ConfirmDialogContent } from '../../common/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Token } from 'src/app/models/token';

@Component({
  selector: 'app-owners-file-steps',
  templateUrl: './owners-file-steps.component.html',
  styleUrls: ['./owners-file-steps.component.scss']
})
export class OwnersFileStepsComponent implements AfterViewInit, OnInit {

  @ViewChild('stepper', null) stepper: MatStepper;
  public ownersFileHistory: OwnersFileHistory;
  public stepIndex = 1;
  public showExitButton = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
    private snackbarService: SnackbarService,
    private ownersFileHistoryService: OwnersFileHistoryService
  ) {
    this.verifyPostToSAPPermissions();
  }

  ngOnInit(): void {
    this.verifyPostToSAPPermissions();
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'number';
    const id =
      parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10) || 0;
    if (id > 0) {
      this.getOwnersFileHistory(id);
    }
  }

  public getOwnersFileHistory(id: number) {
    this.ownersFileHistoryService.getById(id).subscribe(result => {
      if (result != null && result.statusNumber === 5 ) {
        this.router.navigate(['/uploads']);
      } else {
        this.updateOwnersFileHistory(result);
        this.stepIndex = result.statusNumber;
        setTimeout(() => {
          while (this.stepper.selectedIndex < this.stepIndex - 1) {
            this.stepper.next();
          }
        }, 1);
      }
    });
  }

  public updateOwnersFileHistory(ownersFileHistory: OwnersFileHistory) {
    this.ownersFileHistory = ownersFileHistory;
  }

  public stepOneCancel() {
    this.router.navigate(['/uploads']);
  }

  public nextStep(ownersFileHistoryId?: number) {
    if (this.stepIndex === 3) {
      this.ownersFileHistoryService.generateSapFile(ownersFileHistoryId).subscribe(
        () => {
          this.moveToNextStep();
        }
      );
    } else {
      this.moveToNextStep();
    }
  }

  private moveToNextStep() {
    this.stepIndex += 1;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  public previousStep() {
    this.stepIndex -= 1;
    setTimeout(() => {
      this.stepper.previous();
    }, 1);
  }

  public postToSap(ownersFileHistoryId: number) {

    if ( !this.verifyPostToSAPPermissions()) {
      this.snackbarService.displayWarningMessage('Please contact an endorser in order to have this interface file posted to SAP.');
    } else {
      this.dialogService
      .openDialog(
        ConfirmDialogComponent,
        null,
        new ConfirmDialogContent(
          null,
          'Do you really want to confirm data validation check?'
        )
      )
      .subscribe(resp => {
        if (resp) {
          this.ownersFileHistoryService.moveSapFileToDataFactoryFolder(ownersFileHistoryId).subscribe(
            () => {
              this.snackbarService.displaySuccessMessage('Interface successfully sent to SAP');
              this.showExitButton = true;
              this.router.navigate(['/uploads']);
            }
          );
        }
      });
    }
  }

  goToHistory() {
    this.router.navigate(['/uploads']);
  }

  public verifyPostToSAPPermissions() {
    const helper = new JwtHelperService();
    const token = sessionStorage.getItem('msal.idtoken') as any;
    if (token != null ) {
      const decodedToken = helper.decodeToken(token) as Token;
      if (decodedToken != null && decodedToken.roles != null) {
        return decodedToken.roles.find( x => x === 'Administrator') !== undefined;
      }
    }
    return false;
  }
}
