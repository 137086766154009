import { Component, OnInit, Input } from '@angular/core';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-step4-post-sap',
  templateUrl: './step4-post-sap.component.html',
  styleUrls: ['./step4-post-sap.component.scss'],
})
export class Step4PostSapComponent implements OnInit {
  public ownersFileHistory: OwnersFileHistory;
  public hideRegenerateSAPFileButton = false;

  @Input('ownersFileHistory')
  set ownersFileHistoryInput(value: OwnersFileHistory) {
    if (value) {
      this.ownersFileHistory = value;
      if (
        this.ownersFileHistory != null &&
        this.ownersFileHistory.statusNumber === 5
      ) {
        this.hideRegenerateSAPFileButton = true;
      }
    }
  }

  @Input() stepIndex: number;

  constructor(
    private ownersFileHistoryService: OwnersFileHistoryService,
    private snackBarService: SnackbarService,
  ) {}

  ngOnInit() {}

  downloadSapFile() {
    if (this.ownersFileHistory != null) {
      this.ownersFileHistoryService
        .getSapFile(this.ownersFileHistory.ownersFileHistoryId)
        .subscribe((resp: any) => {
          if (resp.body.size > 0) {
            FileSaver.saveAs(
              resp.body,
              `FB01_${this.ownersFileHistory.ownersFileHistoryId}.dat`,
            );
          }
        });
    } else {
      this.snackBarService.displayErrorMessage(
        `Owners file data not available; please try again.`,
      );
    }
  }

  generateFile() {
    this.ownersFileHistoryService
      .generateSapFile(this.ownersFileHistory.ownersFileHistoryId)
      .subscribe();
  }

  public downloadResultPreviewFile() {
    this.ownersFileHistoryService
      .getResulPreviewFile(this.ownersFileHistory.ownersFileHistoryId)
      .subscribe((resp: any) => {
        if (resp.body.size > 0) {
          FileSaver.saveAs(resp.body, 'result-preview.xlsx');
        }
      });
  }
}
