import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedList } from '../models/paged-list';
import { AccountMapping } from '../models/account-mapping';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountMappingService {
  constructor(private httpClient: HttpClient) {}

  public getAll(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<AccountMapping[]>(`${environment.apiEndpoint}/v1/accountmappings/`, {
        params: filter,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }
  public getById(id: number): Observable<AccountMapping> {
    return this.httpClient.get<AccountMapping>(
      `${environment.apiEndpoint}/v1/accountmappings/${id}`,
    );
  }
  public insert(model: AccountMapping): Observable<AccountMapping> {
    return this.httpClient.post<AccountMapping>(
      `${environment.apiEndpoint}/v1/accountmappings/`,
      model,
    );
  }
  public update(model: AccountMapping): Observable<AccountMapping> {
    return this.httpClient.put<AccountMapping>(
      `${environment.apiEndpoint}/v1/accountmappings/`,
      model,
    );
  }
  public delete(id: number): Observable<AccountMapping> {
    return this.httpClient.delete<AccountMapping>(
      `${environment.apiEndpoint}/v1/accountmappings/${id}`,
    );
  }
}
