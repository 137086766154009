import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private httpClient: HttpClient) { }

  public getAuditLog(auditType: string, period: number, startDate: any, endDate: any): Observable<HttpResponse<Blob>> {
    let url = `${environment.apiEndpoint}/v1/auditlogs/${auditType}/${period}`;
    if (period === 0) {
       url = `${environment.apiEndpoint}/v1/auditlogs/${auditType}`;
    }
    if ((startDate != null && startDate !== '') && (endDate != null && endDate !== '')) {
      moment.locale('en');
      const stDtStr = moment(startDate).format('l');
      const edDtStr = moment(endDate).format('l');
      url = `${environment.apiEndpoint}/v1/auditlogs/${auditType}?startDate=${stDtStr}&endDate=${edDtStr}`;
    }
    return this.httpClient.get<Blob>(url,
      {
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    );
  }
}
