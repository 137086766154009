import { Component, OnInit, Inject } from '@angular/core';
import { AlyeskaAccountService } from 'src/app/services/alyeska-account.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alyeska-account-edit',
  templateUrl: './alyeska-account-edit.component.html',
  styleUrls: ['./alyeska-account-edit.component.scss']
})
export class AlyeskaAccountEditComponent implements OnInit {
  public action: string;
  public form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AlyeskaAccountEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AlyeskaAccountService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.action = this.data.action != null ? this.data.action : 'Create New';
    this.buildFormGroup();
  }

  private buildFormGroup() {
    this.form = this.formBuilder.group({
      alyeskaAccountId: [
        this.data.content != null ? this.data.content.alyeskaAccountId : 0
      ],
      ferc: [
        this.data.content != null ? this.data.content.ferc : '',
        Validators.required
      ],
      accountNumber: [
        this.data.content != null ? this.data.content.accountNumber : '',
        Validators.required
      ],
      description: [
        this.data.content != null ? this.data.content.description : '',
        Validators.required
      ],
      notUsedForCalculation: [
        this.data.content != null ? this.data.content.notUsedForCalculation : false
      ],
      usedForBalance: [
        this.data.content != null ? this.data.content.usedForBalance : false
      ]
    });
  }
  public save() {
    if (this.form.invalid) {
      return;
    }
    if (this.data.content === null) {
      this.service.insert(this.form.value).subscribe(() => {
        this.dialogRef.close('success');
      });
    } else {
      this.service.update(this.form.value).subscribe(() => {
        this.dialogRef.close('success');
      });
    }
  }
  public getControl(name: string) {
    return this.form.get(name);
  }
  public cancel() {
    this.dialogRef.close('cancelled');
  }
}
