import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '../components/common/snackbar/snackbar.component';
import { ResponseError } from '../models/response-error';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private durationInSeconds = 3;

  constructor(private snackBar: MatSnackBar) {}

  private openSnackBar(
    fromComponent: any,
    extaCssClass: string,
    content: string | any,
    type: string,
    forever: boolean = false
  ) {
    this.snackBar.openFromComponent(fromComponent, {
      duration: forever ? 3600 * 1000 : this.durationInSeconds * 1000,
      panelClass: extaCssClass,
      data: { message: content, messageType: type }
    });
  }
  public displayWarningMessage(message: string) {
    this.openSnackBar(SnackbarComponent, 'warn-message', message, 'warn');
  }

  public displayErrorMessage(message: string | ResponseError, forever = false) {
    this.openSnackBar(
      SnackbarComponent,
      'error-message',
      message,
      'error',
      forever
    );
  }

  public displayInfoMessage(message: string) {
    this.openSnackBar(SnackbarComponent, 'info-message', message, 'info');
  }

  public displaySuccessMessage(message: string | any) {
    this.openSnackBar(SnackbarComponent, 'success-message', message, 'success');
  }
}
