import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountMappingService } from 'src/app/services/account-mapping.service';
import { AlyeskaAccount } from 'src/app/models/alyeska-account';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-account-mapping-edit',
  templateUrl: './account-mapping-edit.component.html',
  styleUrls: ['./account-mapping-edit.component.scss']
})
export class AccountMappingEditComponent implements OnInit {
  public action: string;
  public form: FormGroup;
  public accountMappingPercentageTypes: any[] = [
    { name: 'ExxonMobil Ownership', value: 1 },
    { name: 'Pipeline + Tankage', value: 2 },
    { name: 'ExxonMobil Only', value: 3 }
  ];
  public filteredAlyeskaAccounts: AlyeskaAccount[];
  constructor(
    public dialogRef: MatDialogRef<AccountMappingEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AccountMappingService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.action = this.data.action != null ? this.data.action : 'Create New';

    this.buildFormGroup();
  }

  private buildFormGroup() {
    this.form = this.formBuilder.group({
      accountMappingId: [
        this.data.content != null ? this.data.content.accountMappingId : 0,
        Validators.required
      ],
      alyeskaAccountId: [
        this.data.content != null ? this.data.content.alyeskaAccountId : 0,
        Validators.required
      ],
      ferc: new FormControl({
        value:
          this.data.content != null
            ? this.data.content.alyeskaAccount.ferc
            : null,
        disabled: true
      }),
      accountNumber: new FormControl({
        value:
          this.data.content != null
            ? this.data.content.alyeskaAccount.accountNumber
            : null,
        disabled: true
      }),
      alyeskaDescription: new FormControl({
        value:
          this.data.content != null
            ? this.data.content.alyeskaAccount.description
            : null,
        disabled: true
      }),
      accountMappingPercentageTypeEnum: [
        this.data.content != null
          ? this.data.content.accountMappingPercentageTypeEnum
          : null,
        Validators.required
      ],
      glAccount: [
        this.data.content != null ? this.data.content.glAccount : null,
        Validators.compose([
          Validators.min(1),
          Validators.max(999999999),
          Validators.required
        ])
      ],
      description: [
        this.data.content != null ? this.data.content.description : null
      ],
      costCenter: [
        this.data.content != null ? this.data.content.costCenter : null
      ],
      material: [this.data.content != null ? this.data.content.material : null],
      plant: [this.data.content != null ? this.data.content.plant : null],
      businessArea: [
        this.data.content != null ? this.data.content.businessArea : null
      ],
      transactionType: [
        this.data.content != null ? this.data.content.transactionType : null
      ],
      negativeTransactionType: [
        this.data.content != null ? this.data.content.negativeTransactionType : null
      ],
      assignment: [
        this.data.content != null ? this.data.content.assignment : null
      ],
      text: [this.data.content != null ? this.data.content.text : null],
      unitOfMeasure: [
        this.data.content != null ? this.data.content.unitOfMeasure : null
      ],
      quantity: [
        this.data.content != null ? this.data.content.quantity : null
      ],
      functionalArea: [
        this.data.content != null ? this.data.content.functionalArea : null
      ],
      costElement: [
        this.data.content != null ? this.data.content.costElement : null
      ],
      taxCode: [this.data.content != null ? this.data.content.taxCode : null],
      taxJurisdiction: [
        this.data.content != null ? this.data.content.taxJurisdiction : null
      ],
      controllingArea: [
        this.data.content != null ? this.data.content.controllingArea : null
      ],
      profitCenter: [
        this.data.content != null ? this.data.content.profitCenter : null
      ],
      trandingPartner: [
        this.data.content != null ? this.data.content.trandingPartner : null
      ]
    });
    this.form.markAllAsTouched();
  }

  private checkIfIsNullOrEmpty(value: any): boolean {
    return value == null || (value != null && value === '');
  }

  private checkIfNeitherNullNorEmpty(value: any): boolean {
    return value != null && value !== '';
  }

  private validateQuantityMaterialAndUnitOfMeasureCombined(formGroup: FormGroup): boolean {
    const material = formGroup.get('material').value;
    const unitOfMeasure = formGroup.get('unitOfMeasure').value;
    const quantity = formGroup.get('quantity').value;
    return (
      (
        this.checkIfNeitherNullNorEmpty(material) &&
        this.checkIfNeitherNullNorEmpty(unitOfMeasure) &&
        this.checkIfNeitherNullNorEmpty(quantity)
      )
      ||
      (
        this.checkIfIsNullOrEmpty(material) &&
        this.checkIfIsNullOrEmpty(unitOfMeasure) &&
        this.checkIfIsNullOrEmpty(quantity)
      )
    ) ? true : false;
  }

  private validateTaxJurisdictionAndTaxCodeCombined(formGroup: FormGroup): boolean {
    const taxCode = formGroup.get('taxCode').value;
    const taxJurisdiction = formGroup.get('taxJurisdiction').value;
    return (
      (
        this.checkIfNeitherNullNorEmpty(taxCode) &&
        this.checkIfNeitherNullNorEmpty(taxJurisdiction)
      )
      ||
      (
        this.checkIfIsNullOrEmpty(taxCode) &&
        this.checkIfIsNullOrEmpty(taxJurisdiction)
      )
    ) ? true : false;
  }

  public save() {
    if (this.form.invalid) {
      return;
    }

    if (this.checkIfNeitherNullNorEmpty(this.form.get('negativeTransactionType').value)
    && this.checkIfIsNullOrEmpty(this.form.get('transactionType').value)) {
      this.snackbarService.displayErrorMessage('Positive transaction type is mandatory if you add a negative transaction type value.');
      return;
    }

    if (!this.validateQuantityMaterialAndUnitOfMeasureCombined(this.form)) {
        this.snackbarService.displayErrorMessage('Quantity, Material and Unit of Measure, together, are mandatory.');
        return;
    }

    if (!this.validateTaxJurisdictionAndTaxCodeCombined(this.form)) {
      this.snackbarService.displayErrorMessage('If you specify a Tax Code, Tax Jurisdiction is mandory (and vice versa).');
      return;
    }

    if (this.checkIfNeitherNullNorEmpty(this.form.get('unitOfMeasure').value)) {
      if ((this.form.get('unitOfMeasure').value as string).length < 3) {
        this.snackbarService.displayErrorMessage('Unit of measure needs to have 3 characters.');
        return;
      }
    }

    if (this.data.content === null) {
      this.service.insert(this.form.value).subscribe(
        () => {
          this.dialogRef.close('success');
        },
        () => {
          this.dialogRef.close('cancelled');
        }
      );
    } else {
      this.service.update(this.form.value).subscribe(
        () => {
          this.dialogRef.close('success');
        },
        () => {
          this.dialogRef.close('cancelled');
        }
      );
    }
  }

  public getControl(name: string) {
    return this.form.get(name);
  }

  public cancel() {
    this.dialogRef.close('cancelled');
  }
}
