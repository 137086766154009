import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, forkJoin } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { DialogService } from 'src/app/services/dialog.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogContent
} from '../../common/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as FileSaver from "file-saver";

@Component({
  selector: 'app-owners-file-get',
  templateUrl: './owners-file-get.component.html',
  styleUrls: ['./owners-file-get.component.scss']
})
export class OwnersFileGetComponent implements AfterViewInit {
  constructor(
    private uploadHistoryService: OwnersFileHistoryService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private ownersFileDataService: OwnersFileHistoryService
  ) {
    this.filterForm = this.formBuilder.group({
      year: '',
      month: ''
    });
  }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  years: number[];
  months: number[];
  public uploads: OwnersFileHistory[] = [];
  public filterForm: FormGroup;
  public displayedColumns: string[] = [
    'createddate',
    'createdby',
    'year',
    'month',
    'status',
    'postedsapdate',
    'actions'
  ];
  resultsLength = 0;

  ngAfterViewInit() {
    forkJoin([this.loadYears(), this.loadMonths()]).subscribe(results => {
      this.years = results[0];
      this.months = results[1];
    });

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadUploads(this.getFilter(false));
        }),
        map(data => {
          this.resultsLength = data.totalRecordCount;
          return data.items;
        })
      )
      .subscribe(data => (this.uploads = data));
  }

  loadYears() {
    return this.uploadHistoryService.getYears();
  }
  loadMonths() {
    return this.uploadHistoryService.getMonths();
  }
  loadUploads(filter: any = null) {
    return this.uploadHistoryService.getAll(filter);
  }

  public downloadOriginalFile(id) {
    this.uploadHistoryService.getOriginalFile(id).subscribe((resp: any) => {
      FileSaver.saveAs(resp.body, 'owners.txt');
    });
  }
  public goToUploadSteps() {
    this.router.navigate(['/uploads/create']);
  }

  public editItem(ownersFileHistoryId: number) {
    this.router.navigate([`/uploads/edit/${ownersFileHistoryId}`]);
  }

  public delete(id) {
    this.dialogService
      .openDialog(
        ConfirmDialogComponent,
        null,
        new ConfirmDialogContent(
          null,
          'Do you really want to cancel this process?'
        )
      )
      .subscribe(resp => {
        if (resp) {
          this.uploadHistoryService.delete(id).subscribe(() => {
            this.snackbarService.displaySuccessMessage(
              'Process canceled successfully.'
            );
            this.filterList();
          });
        }
      });
  }

  public getFilter(resetPageIndex: boolean): any {
    const filter = this.filterForm.value;
    if (resetPageIndex) {
      this.paginator.pageIndex = 0;
      filter.pageNumber = 1;
    } else {
      filter.pageNumber = this.paginator.pageIndex + 1;
    }

    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;

    filter.pageSize = this.paginator.pageSize;
    return filter;
  }

  public filterList() {
    this.loadUploads(this.getFilter(true)).subscribe(data => {
      this.uploads = data.items;
      this.resultsLength = data.totalRecordCount;
    });
  }

  public downloadResultPreviewFile(id) {
    this.ownersFileDataService
      .getResulPreviewFile(id)
      .subscribe((resp: any) => {
        if (resp.body.size > 0) {
          FileSaver.saveAs(resp.body, 'results.xlsx');
        }
      });
  }
}
