import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private spinnerService: SpinnerService) {}

  public showLoadingSpinner: boolean;

  ngOnInit() {
    this.spinnerService.status.subscribe((show: boolean) => {
      this.showLoadingSpinner = show;
    });
  }
}
