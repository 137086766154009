import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { Upload } from 'src/app/models/upload';
import { startWith, switchMap, map } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { merge, Observable, of as observableOf, forkJoin } from 'rxjs';
import { Location } from 'src/app/models/location';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PagedList } from 'src/app/models/paged-list';
import { LocationEditComponent } from '../location-edit/location-edit.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogContent
} from '../../common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-location-get',
  templateUrl: './location-get.component.html',
  styleUrls: ['./location-get.component.scss']
})
export class LocationGetComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'code',
    'description',
    'pipeline',
    'tankage',
    'actions'
  ];
  public resultsLength = 0;
  public data: Location[] = [];
  public filterForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    this.filterForm = this.formBuilder.group({
      code: ''
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadLocations(this.getFilter(false));
        }),
        map(data => {
          this.resultsLength = data.totalRecordCount;
          return data.items;
        })
      )
      .subscribe(data => (this.data = data));
  }

  public loadLocations(filter: any = null): Observable<PagedList> {
    return this.locationService.getAll(filter);
  }
  public editItem(value: Location) {
    this.dialogService
      .openDialog(LocationEditComponent, null, {
        action: 'Edit',
        content: value
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Location edited successfully'
          );
        }
        this.filterList();
      });
  }
  public newItem() {
    this.dialogService
      .openDialog(LocationEditComponent, null, {
        action: 'Create New',
        content: null
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Location created successfully'
          );
          this.filterList();
        }
      });
  }

  public deleteItem(location: Location) {
    this.dialogService
      .openDialog(
        ConfirmDialogComponent,
        null,
        new ConfirmDialogContent(
          null,
          'Do you really want to delete this location?'
        )
      )
      .subscribe(resp => {
        if (resp) {
          this.locationService.delete(location.locationId).subscribe(() => {
            this.snackbarService.displaySuccessMessage(
              'Location deleted successfully'
            );
            this.filterList();
          });
        }
      });
  }

  public getFilter(resetPageIndex: boolean): any {
    const filter = this.filterForm.value;
    if (resetPageIndex) {
      this.paginator.pageIndex = 0;
      filter.pageNumber = 1;
    } else {
      filter.pageNumber = this.paginator.pageIndex + 1;
    }

    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;

    filter.pageSize = this.paginator.pageSize;
    return filter;
  }

  public filterList() {
    this.loadLocations(this.getFilter(true)).subscribe(data => {
      this.data = data.items;
      this.resultsLength = data.totalRecordCount;
    });
  }
}
