import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedList } from '../models/paged-list';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private httpClient: HttpClient) {}

  public getAll(filter: any = null): Observable<PagedList> {
    return this.httpClient
      .get<Location[]>(`${environment.apiEndpoint}/v1/locations/`, {
        params: filter,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return new PagedList(response);
        }),
      );
  }
  public getById(id: number): Observable<Location> {
    return this.httpClient.get<Location>(
      `${environment.apiEndpoint}/v1/locations/${id}`,
    );
  }
  public insert(model: Location): Observable<Location> {
    return this.httpClient.post<Location>(
      `${environment.apiEndpoint}/v1/locations/`,
      model,
    );
  }
  public update(model: Location): Observable<Location> {
    return this.httpClient.put<Location>(
      `${environment.apiEndpoint}/v1/locations/`,
      model,
    );
  }
  public delete(id: number): Observable<Location> {
    return this.httpClient.delete<Location>(
      `${environment.apiEndpoint}/v1/locations/${id}`,
    );
  }
}
