import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AccountMapping } from 'src/app/models/account-mapping';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountMappingService } from 'src/app/services/account-mapping.service';
import { PagedList } from 'src/app/models/paged-list';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import {
  ConfirmDialogComponent,
  ConfirmDialogContent
} from '../../common/confirm-dialog/confirm-dialog.component';
import { AccountMappingEditComponent } from '../alyeska-account-edit/account-mapping-edit.component';

@Component({
  selector: 'app-account-mapping-get',
  templateUrl: './account-mapping-get.component.html',
  styleUrls: ['./account-mapping-get.component.scss']
})
export class AccountMappingGetComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'ferc',
    'accountNumber',
    'glAccount',
    'percentage',
    'usedForBalance',
    'actions'
  ];
  public resultsLength = 0;
  public data: AccountMapping[] = [];
  public filterForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private accountMappingService: AccountMappingService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    this.filterForm = this.formBuilder.group({
      ferc: '',
      accountNumber: '',
      glAccount: '',
      showMissingOnesOnly: false,
      showBalanceAccountMappingOnly: false
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadAccountMappings(this.getFilter(false));
        }),
        map(data => {
          this.resultsLength = data.totalRecordCount;
          return data.items;
        })
      )
      .subscribe(data => (this.data = data));
  }

  public loadAccountMappings(filter: any = null): Observable<PagedList> {
    return this.accountMappingService.getAll(filter);
  }

  public editItem(value: AccountMapping) {
    this.accountMappingService
      .getById(value.accountMappingId)
      .subscribe(result => {
        this.dialogService
          .openDialog(AccountMappingEditComponent, null, {
            action: 'Edit',
            content: result
          })
          .subscribe(resp => {
            if (resp !== 'cancelled') {
              this.snackbarService.displaySuccessMessage(
                'Account Mapping edited successfully'
              );
            }
            this.filterList();
          });
      });
  }

  public newItem() {
    this.dialogService
      .openDialog(AccountMappingEditComponent, null, {
        action: 'Create New',
        content: null
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Account Mapping created successfully'
          );
          this.filterList();
        }
      });
  }

  public deleteItem(accountMapping: AccountMapping) {
    this.dialogService
      .openDialog(
        ConfirmDialogComponent,
        null,
        new ConfirmDialogContent(
          null,
          'Do you really want to delete this account mapping?'
        )
      )
      .subscribe(resp => {
        if (resp) {
          this.accountMappingService
            .delete(accountMapping.accountMappingId)
            .subscribe(() => {
              this.snackbarService.displaySuccessMessage(
                'Account Mapping deleted successfully'
              );
              this.filterList();
            });
        }
      });
  }

  public getFilter(resetPageIndex: boolean): any {
    const filter = this.filterForm.value;
    if (resetPageIndex) {
      this.paginator.pageIndex = 0;
      filter.pageNumber = 1;
    } else {
      filter.pageNumber = this.paginator.pageIndex + 1;
    }

    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;

    filter.pageSize = this.paginator.pageSize;
    return filter;
  }

  public filterList() {
    this.loadAccountMappings(this.getFilter(true)).subscribe(data => {
      this.data = data.items;
      this.resultsLength = data.totalRecordCount;
    });
  }
}
