import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AlyeskaAccount } from 'src/app/models/alyeska-account';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlyeskaAccountService } from 'src/app/services/alyeska-account.service';
import { PagedList } from 'src/app/models/paged-list';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AlyeskaAccountEditComponent } from '../alyeska-account-edit/alyeska-account-edit.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogContent
} from '../../common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-alyeska-account-get',
  templateUrl: './alyeska-account-get.component.html',
  styleUrls: ['./alyeska-account-get.component.scss']
})
export class AlyeskaAccountGetComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'ferc',
    'accountNumber',
    'description',
    'usedForCalculation',
    'usedForBalance',
    'actions'
  ];
  public resultsLength = 0;
  public data: AlyeskaAccount[] = [];
  public filterForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private alyeskaAccountService: AlyeskaAccountService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    this.filterForm = this.formBuilder.group({
      ferc: '',
      accountNumber: '',
      description: ''
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadAlyeskaAccounts(this.getFilter(false));
        }),
        map(data => {
          this.resultsLength = data.totalRecordCount;
          return data.items;
        })
      )
      .subscribe(data => (this.data = data));
  }

  public loadAlyeskaAccounts(filter: any = null): Observable<PagedList> {
    return this.alyeskaAccountService.getAll(filter);
  }
  public editItem(value: AlyeskaAccount) {
    this.dialogService
      .openDialog(AlyeskaAccountEditComponent, null, {
        action: 'Edit',
        content: value
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Alyeska Account edited successfully'
          );
        }
        this.filterList();
      });
  }
  public newItem() {
    this.dialogService
      .openDialog(AlyeskaAccountEditComponent, null, {
        action: 'Create New',
        content: null
      })
      .subscribe(resp => {
        if (resp !== 'cancelled') {
          this.snackbarService.displaySuccessMessage(
            'Alyeska Account created successfully'
          );
          this.filterList();
        }
      });
  }

  public deleteItem(alyeskaAccount: AlyeskaAccount) {
    this.dialogService
      .openDialog(
        ConfirmDialogComponent,
        null,
        new ConfirmDialogContent(
          null,
          'Do you really want to delete this alyeska account?'
        )
      )
      .subscribe(resp => {
        if (resp) {
          this.alyeskaAccountService
            .delete(alyeskaAccount.alyeskaAccountId)
            .subscribe(() => {
              this.snackbarService.displaySuccessMessage(
                'Alyeska Account deleted successfully'
              );
              this.filterList();
            });
        }
      });
  }

  public getFilter(resetPageIndex: boolean): any {
    const filter = this.filterForm.value;
    if (resetPageIndex) {
      this.paginator.pageIndex = 0;
      filter.pageNumber = 1;
    } else {
      filter.pageNumber = this.paginator.pageIndex + 1;
    }

    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;

    filter.pageSize = this.paginator.pageSize;
    return filter;
  }

  public filterList() {
    this.loadAlyeskaAccounts(this.getFilter(true)).subscribe(data => {
      this.data = data.items;
      this.resultsLength = data.totalRecordCount;
    });
  }
}
