import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { OwnersDataResultPreview } from 'src/app/models/owners-data-result-preview';
import { MatSort, MatPaginator } from '@angular/material';
import { OwnersFileHistoryService } from 'src/app/services/owners-file-history.service';
import { OwnersFileHistory } from 'src/app/models/owners-file-history';
import { merge } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';
import * as FileSaver from "file-saver";

@Component({
  selector: 'app-step3-result-preview',
  templateUrl: './step3-result-preview.component.html',
  styleUrls: ['./step3-result-preview.component.scss']
})
export class Step3ResultPreviewComponent implements AfterViewInit {
  public resultDisplayedColumns: string[] = [
    'ferc',
    'account',
    'glAccount',
    'beginningBalance',
    'currentBalanace',
    'pipeline',
    'tankage',
    'calcResult'
  ];

  public resultData: OwnersDataResultPreview[] = [];
  public resultsLength = 0;

  public uploadedFileName = 'Sample uploaded file name';
  public uploadedFileYear = '2019';
  public uploadedFileTotalItems = '1250';
  public uploadedFileTotalIssues = '0';
  public uploadedFileMonth = '08';

  public ownersFileHistory: OwnersFileHistory;
  @Input('ownersFileHistory')
  set ownersFileHistoryInput(value: OwnersFileHistory) {
    if (value) {
      this.ownersFileHistory = value;
    }
  }
  @Output() ownersFileHistoryOut = new EventEmitter<OwnersFileHistory>();
  @Input() stepIndex: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private ownersFileDataService: OwnersFileHistoryService) {}

  ngAfterViewInit() {
    if (this.stepIndex === 3) {
      if (this.ownersFileHistory.statusNumber === 3) {
        this.process();
      } else if (this.ownersFileHistory.statusNumber === 4) {
        this.getResults();
      }
    }
  }

  process() {
    this.ownersFileDataService
      .process(this.ownersFileHistory.ownersFileHistoryId)
      .subscribe(result => {
        this.ownersFileHistory.statusNumber = 4;
        this.ownersFileHistoryOut.emit(this.ownersFileHistory);
        this.getResults();
      });
  }

  getResults() {
    if (this.stepIndex === 3) {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.ownersFileDataService.getResulPreview(
              this.getFilter(this.ownersFileHistory.ownersFileHistoryId)
            );
          }),
          map(data => {
            this.resultsLength = data.totalRecordCount;
            return data.items;
          })
        )
        .subscribe(data => {
          this.resultData = data;
        });
    }
    return;
  }

  public getFilter(ownersFileId: number): any {
    const filter: any = { ownersFileHistoryId: ownersFileId };
    filter.orderBy =
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active;
    filter.pageNumber = this.paginator.pageIndex + 1;
    filter.pageSize = this.paginator.pageSize;
    filter.ownersFileHistoryId = this.ownersFileHistory.ownersFileHistoryId;
    return filter;
  }

  public downloadResultPreviewFile() {
    this.ownersFileDataService
      .getResulPreviewFile(this.ownersFileHistory.ownersFileHistoryId)
      .subscribe((resp: any) => {
        if (resp.body.size > 0) {
          FileSaver.saveAs(resp.body, 'result-preview.xlsx');
        }
      });
  }
}
